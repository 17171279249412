<template>
  <div>
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="发布日期">
        <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        align="right">
        </el-date-picker>
      </el-form-item>

      <el-form-item label="发布国家">
        <el-select v-model="dataForm.sendCountry" multiple clearable placeholder="all"
                   @change="selectCountryChange">
          <el-option v-for="item in countryCodeOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户语种">
        <el-select v-model="dataForm.lang" clearable multiple placeholder="all" @change="selectLangChange">
          <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布状态">
        <el-select v-model="dataForm.status" clearable placeholder="all">
          <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="refresh()">重置</el-button>
        <el-button @click="addOrUpdateHandle(0)" type="danger">新增</el-button>
        <!-- <el-button @click="exportList()"  style="background-color: #00BFBF; color: #fff;">导出</el-button> -->
      </el-form-item>
    </el-form>

    <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%; ">

      <el-table-column prop="id" header-align="center" align="center" label="ID">
      </el-table-column>
      <el-table-column prop="anchorUid" width="200px" header-align="center" align="center" label="主播uid">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.anchorUid ? '/' : scope.row.anchorUid }}
                    </span>
        </template>
      </el-table-column>
      <el-table-column prop="anchorName" header-align="center" align="center" label="主播名称" width="200px">
        <template slot-scope="scope">
          {{ getVirUserLabel(scope.row.anchorUid, anchorList) }}
        </template>
      </el-table-column>
      <el-table-column prop="pushImage" header-align="center" align="center" label="图片" width="100px">
        <template slot-scope="scope">

          <img v-if="scope.row.pushImage !== ''&& scope.row.pushImage !== null " style="width: 40px; height: 40px" :src="scope.row.pushImage" />
          <span v-else> 使用头像:
             <img  style="width: 40px; height: 40px" :src="getAnchorImg(scope.row.anchorUid, anchorList)" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="sendTime" width="100px" header-align="center" align="center" label="发送时间">
      </el-table-column>

      <el-table-column prop="sendCountry" header-align="center" align="center" label="发布国家">
        <template slot-scope="scope">
          {{ scope.row.isUserPackage === 1 ? '/' : splitStr(scope.row.sendCountry) }}
        </template>
      </el-table-column>

      <el-table-column prop="sendLang" header-align="center" align="center" label="用户语种">
        <template slot-scope="scope">
          {{ scope.row.isUserPackage === 1 ? '/' : splitStr(scope.row.sendLang) }}
        </template>
      </el-table-column>

      <el-table-column prop="pushContent" width="200px" header-align="center" align="center" label="push内容">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.pushContent ? '/' : scope.row.pushContent }}
                    </span>
        </template>
      </el-table-column>


      <el-table-column prop="remark" width="200" header-align="center" align="center" label="备注">
        <template slot-scope="scope">
                    <span style="white-space: pre-wrap">
                        {{ !scope.row.remark ? '/' : scope.row.remark }}
                    </span>
        </template>
      </el-table-column>

      <el-table-column prop="status" width="120" sortable header-align="center" align="center" label="发布状态">
        <template slot-scope="scope">
          <div>
            <el-tag v-if="scope.row.status === 1" type="info">导入用户</el-tag>
            <el-tag v-if="scope.row.status === 2" type="primary">待发送</el-tag>
            <el-tag v-else-if="scope.row.status === 3" type="warning">发送中</el-tag>
            <el-tag v-else-if="scope.row.status === 4" type="success">已发送</el-tag>
            <el-tag v-else-if="scope.row.status === 0" type="danger">已终止</el-tag>
            <!-- <span v-else> / </span> -->

          </div>
        </template>
      </el-table-column>

      <el-table-column prop="opName" header-align="center" align="center" label="操作人">
      </el-table-column>

      <el-table-column prop="atime" width="100px" header-align="center" align="center" label="创建时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status === 1 || scope.row.status === 2 || scope.row.status === 3" type="text"
                     style="color: rgba(0, 191, 191, 1)" @click="handleStopPush(scope.row.id)">终止任务
          </el-button>
          <el-button type="text" @click="addOrUpdateHandle(scope.row)" style="color: rgba(236, 128, 141, 1)">查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
                   :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                   layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>
<script>
import AddOrUpdate from "./push-add-or-update";
import { langOpt, pickerOptions } from "@/views/common/commonOpt";

const sourceDataForm = {
  id: '',
  isPackage: '',
  lang: '',
  status: '',
  sendCountry: '',
  startTime: '',
  endTime: '',
  createTimeArr: [],
}
export default {
  data() {
    return {
      dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      anchorList:[],
      dataListLoading: false,
      dataListSelections: [],
      countryCodeOpt: [
        {
          value: 'all',
          label: 'ALL'
        },
      ],
      langOpt: langOpt,
      statusOpt: [
        {
          value: 0,
          label: '已终止'
        },
        {
          value: 1,
          label: '导入用户'
        },
        {
          value: 2,
          label: '待发送'
        },
        {
          value: 3,
          label: '发送中'
        },
        {
          value: 4,
          label: '已发送'
        }
      ],
      isPackageOpt: [
        {
          value: 0,
          label: '否'
        },
        {
          value: 1,
          label: '是'
        },
      ],
      pickerOptions: pickerOptions,
      addOrUpdateVisible: true
    }
  },
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  created(){
    this.getAllAnchor();
  },
  methods: {
    refresh() {
      this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
      this.pageIndex = 1
      this.pageSize = 10
      this.getDataList()
    },
    selectLangChange(val) {
      if (val.includes('all')) {
        this.dataForm.lang = val.filter(item => item == 'all')
      }
    },
    selectCountryChange(val) {
      if (val.includes('all')) {
        this.dataForm.sendCountry = val.filter(item => item == 'all')
      }
    },
    splitStr(str) {
      let list = str.replace(/\[|]/g, '').split(',')
      return list.join('、').replace(/["']/g, "")
    },


    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/admin/anchorPush/list'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
          endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
          lang: this.dataForm.lang.length === 0 ? '' : (this.dataForm.lang.includes('all') ? 'all' : JSON.stringify(this.dataForm.lang).replace(/["]/g, "'").replace(/\\/g, "")),
          // sendCountry: this.dataForm.sendCountry.length === 0 ? '' : (this.dataForm.sendCountry.includes('all') ? 'all' : JSON.stringify(this.dataForm.sendCountry).replace(/\"/g, "'").replace(/\\/g, "")),
          sendCountry: this.dataForm.sendCountry.length === 0 ? '' : 'all',
          page: this.pageIndex,
          limit: this.pageSize,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.result.data
          this.totalPage = data.result.last_page
        } else {
          this.$message.error(data.msg)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    getVirUserLabel(anchorUid, anchorList) {
      let userOpt = anchorList.find(item => item['uid'] == anchorUid)
      return !userOpt ? anchorUid : userOpt['nickname']
    },
    getAnchorImg(anchorUid, anchorList) {
      let userOpt = anchorList.find(item => item['uid'] == anchorUid)
      return !userOpt ? anchorUid : userOpt['avatar']
    },
    getAllAnchor() {
      this.$http({
        url: this.$http.adornUrl('/admin/anchor/list'),
        method: 'post',
        data: this.$http.adornData({
          status:0,
          page:0,
          limit: 1000,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.anchorList = data.result.data
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    handleStopPush(id) {
      this.$confirm('点击确认后本条push不会被发送，请确认是否取消推送此条push', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/anchorPush/pause?taskId=${id}`),
          method: 'post',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList()
            this.$message({
              message: '终止成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.visible = false
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

  },
}
</script>

<style scoped lang="scss">
.el-table {
  .el-button+.el-button {
    margin-left: 0;
  }
}
</style>
